



































































































import { Component, ProvideReactive, Ref, Vue } from 'vue-property-decorator';
import DateUtil from 'global-ui/packages/utils/DateUtil';
import { getTaskList, getTaskDetail, getTaskHistoryList } from '@/api/flow-design/FlowApi';
import TaskDelegate from '@/views/data-manage/task-delegate.vue';
import TaskJump from '@/views/data-manage/task-jump.vue';
import FormViewer from '@/components/form-design/viewer/form-viewer.vue';
import FlowGraph from '@/components/flow-design/flow-graph.vue';
import { deleteManage } from '@/api/data-manage/TaskApi';
import { saveFormData } from '@/api/form-design/FormApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import FlowLogs from '@/views/home/flow2/flow-logs.vue';
@Component({
  name: 'TaskManage',
  components: { TaskJump, TaskDelegate, FormViewer, FlowGraph, FlowLogs }
})
export default class TaskManage extends Vue {
  // 流程转交
  @Ref() readonly taskDelegate;

  // 流程跳转
  @Ref() readonly taskJump;

  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;
  @ProvideReactive() previewAllForm: string = '';

  // 弹框是否显示
  dialogVisible: boolean = false;

  // 表单数据
  taskData: any = {};
  // 表单弹窗
  formVisiable: boolean = false;

  // 当前任务的table
  tableData: any = [];
  logs: any = [];
  taskDataId: string = '';
  currentTaskId: string = '';
  // 是否显示跳转按钮（只有未结束的有跳转按钮）
  jumpButtonShow = false;

  // 关闭弹窗
  handleCloseDialog() {
    this.dialogVisible = false;
    this.previewAllForm = '';
  }

  // 打开弹框
  initDialog(row) {
    this.dialogVisible = true;
    this.taskData = row;
    this.formId = row.formId;
    this.formDataId = row.dataInstanceId;
    this.previewAllForm = 'seeAll';
    // 格式化开始时间
    if (row.startTime) {
      this.taskData.startTime = DateUtil.format(DateUtil.parse(row.startTime));
    }
    // 格式化结束时间
    if (row.endTime) {
      this.taskData.endTime = DateUtil.format(DateUtil.parse(row.endTime));
    }

    // 格式化状态
    if (row.end) {
      this.taskData.endStatus = '已结束';
    } else {
      this.taskData.endStatus = '未结束';
    }

    // 如果状态是未结束则显示跳转按钮
    if (!this.taskData.end) {
      this.jumpButtonShow = true;
    }

    if (row.runtimeId) {
      // 获取任务列表
      getTaskList({ runtimeId: row.runtimeId }).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          this.tableData = res.data;
          if (res.data && res.data.length > 0) {
            this.taskDataId = res.data[0].id;
          } else {
            this.taskDataId = '';
          }
          if (this.taskData.end) {
            getTaskHistoryList({ runtimeId: row.runtimeId }).then((res1: Record<string, any>) => {
              if (res1.data && res1.data.length > 0) {
                this.taskDataId = res1.data[0].id;
              } else {
                this.taskDataId = '';
              }
              getTaskDetail({ id: this.taskDataId }).then((res: any) => {
                if (res.code == '1' && res.data) {
                  this.currentTaskId = res.data.currentTask.id;
                  this.logs = this.buildLogs(res.data.taskFlows, res.data.taskHistories);
                } else {
                  this.logs = [];
                  setTimeout(() => {
                    this.$forceUpdate();
                  }, 300);
                }
              });
            });
          } else {
            getTaskDetail({ id: this.taskDataId }).then((res: any) => {
              if (res.code == '1' && res.data) {
                this.currentTaskId = res.data.currentTask.id;
                this.logs = this.buildLogs(res.data.taskFlows, res.data.taskHistories);
              } else {
                this.logs = [];
                setTimeout(() => {
                  this.$forceUpdate();
                }, 300);
              }
            });
          }
        }
      });
    }
  }
  /**
   * 构建流程日志
   */
  buildLogs(taskFlows, taskHistories) {
    let result: any = [];
    let taskHistoryMap: any = {};
    taskHistories.forEach(historyItem => {
      taskHistoryMap[historyItem.id] = historyItem;
    });
    taskFlows.forEach(nodeItem => {
      let nodeItemTmp = SystemUtil.cloneDeep(nodeItem);
      nodeItemTmp.taskHistories = [];
      if (nodeItem.taskHistories) {
        nodeItem.taskHistories.forEach(logItem => {
          nodeItemTmp.taskHistories.push(taskHistoryMap[logItem.id]);
        });
      }
      result.push(nodeItem);
    });
    return result;
  }
  // 跳转
  jumpClick() {
    this.taskJump.initDialog(this.taskData);
  }

  // 修改表单
  changeFormClick() {
    this.formVisiable = true;
  }
  /**
   * description  提交
   * @param {}
   * @returns {}
   */
  handleSaveFormData() {
    (this.$refs.formViewerRef as any).getFormDataForSave().then(result => {
      saveFormData(result).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
        }
      });
    });
  }

  // 删除流程事项
  deleteClick() {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        deleteManage({ processId: this.taskData.processId, runtimeId: this.taskData.runtimeId })
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              // 提示成功
              this.$message.success(res.message);
              // 关闭弹框
              this.dialogVisible = false;
              this.previewAllForm = '';
              // 刷新列表
              this.$emit('reloadManageList');
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      })
      .catch(() => {});
  }

  // 转交
  turnPerson(row) {
    this.taskDelegate.initDialog(row);
  }

  // 从新加载任务列表
  reloadTasks() {
    // 获取任务列表
    getTaskList({ runtimeId: this.taskData.runtimeId }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.tableData = res.data;
      }
    });
  }
}
