





























import { Component, Ref, Vue } from 'vue-property-decorator';
import UserTransfer from '@/components/user-transfer/index.vue';
import { delegateTask } from '@/api/data-manage/TaskApi';

@Component({
  name: 'TaskDelegate',
  components: { UserTransfer }
})
export default class TaskDelegate extends Vue {
  // ref
  @Ref() readonly formRef;

  // 受理人名称
  receiverName: string = '';

  // 弹框是否显示
  dialogVisible: boolean = false;

  // 表单数据
  form: any = {};

  // 转交人员
  formDelegateTo: any = {};

  // 按钮的loading
  buttonLoading: boolean = false;

  // 关闭弹窗
  handleCloseDialog() {
    this.dialogVisible = false;
  }

  // 打开弹框
  initDialog(row) {
    this.dialogVisible = true;
    this.form = row;
    let receiverName: string = '';

    if (this.form?.performersNames && Array.isArray(this.form?.performersNames)) {
      receiverName = this.form.performersNames.join(',');
    }
    this.receiverName = receiverName;
  }

  // 提交
  submit() {
    // 按钮loading
    this.buttonLoading = true;

    // 请求转交接口
    delegateTask({
      nodeInstanceId: this.form.id,
      identityScope: this.formDelegateTo
    }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        // 按钮loading
        this.buttonLoading = false;

        this.$message.success(res.message);
        this.dialogVisible = false;
        this.$emit('reloadTasks');
      }
    });
  }
}
