
















































































































































































import { Component, Ref, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import page from '@/mixins/page';
import AuthBindResource from '@/views/system/auth-bind-resource.vue';
import { getProcessInstances } from '@/api/data-manage/TaskApi';
import DateUtil from 'global-ui/packages/utils/DateUtil';
import AppTypeApi from '@/api/application/AppTypeApi';
import { getPublishProcessTree } from '@/api/form-design/FormApi';

import AppApi from '@/api/application/AppApi';
import { getUserListData } from '@/api/system/UserApi';
import { getDeptListData } from '@/api/system/OrganizationApi';
import TaskManage from '@/views/data-manage/task-manage.vue';

@Component({
  name: 'AuthList',
  components: {
    TaskManage,
    AuthBindResource
  },
  filters: {
    formatDate(val) {
      if (!val) return;
      return DateUtil.format(DateUtil.parse(val));
    }
  }
})
export default class Flow extends mixins(page) {
  // 是否显示流程归档
  @Prop ({ default: false }) isShowFlow!: boolean;
  // 任务管理弹框
  @Ref() readonly taskManager;

  // 事项管理表格的数据
  formListData: any = [];

  // 查询条件的表单
  formSearchModel: any = {
    applicationTypeId: null,
    applicationId: '',
    processId: null,
    title: '',
    timeScope: '',
    beginTime: '',
    endTime: '',
    status: ''
  };
  flowTypeList: any[] = [];
  // 管理界面是否显示
  manageVisible: boolean = false;

  // App类型树列表
  appTypeTreeData: any[] = [];

  // App应用下拉列表
  appSelectList: any[] = [];

  // 应用树过滤文本框内容
  filterText: string = '';

  // 应用树列表
  appData: any[] = [];
  // 流程类型列表
  processData: any[] = [];

  // 应用树prop
  appTreeProp: Record<string, string> = {
    id: 'id',
    label: 'displayName',
    children: 'children'
  };

  private groupTreeData: any[] = [];
  private userListData: any[] = [];
  private groupTreeNormalizer: any = function (node) {
    return {
      id: node.id,
      label: node.displayName,
      children: node.children
    };
  };
  private groupTreeNormalizer2: any = function (node) {
    return {
      id: node.id,
      label: node.name,
      children: node.children
    };
  };
  // 加载数据
  mounted() {
    if (this.isShowFlow) {
      this.formSearchModel.end = true;
    }
    this.getFormPage();

    this.queryAppTypeTreeData();

    this.getAppTypeTreeData();

    // 获取人员和组织
    this.getDeptList();
    this.getUserList();
    this.getProcessTree();
  }

  /**
   * 表单树格式器
   * @param {number} param
   * @returns {number}
   */
  formNormalizer(node) {
    return {
      id: node.id,
      label: node.text || this.$t(node.text),
      children: node.children
    };
  }

  // 获取流程树
  getProcessTree() {
    // 调用接口
    getPublishProcessTree().then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.processData = res.data;
      }
    });
  }

  /**
   * 格式化时间
   */
  formatDate(val) {
    if (!val) return;
    return DateUtil.format(DateUtil.parse(val));
  }

  // 监控应用分类的选择
  @Watch('formSearchModel.applicationTypeId')
  applicationTypeIdWatcher(newVal: any, oldVal: any) {
    if (newVal) {
      // 根据应用分类加载应用列表
      AppApi.tree({ applicationTypeId: newVal }).then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.appSelectList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }

  // 监控时间范围选择
  @Watch('formSearchModel.timeScope')
  onChildChanged(val: string, oldVal: string) {
    if (val) {
      this.formSearchModel.beginTime = this.formSearchModel.timeScope[0];
      this.formSearchModel.endTime = this.formSearchModel.timeScope[1];
    }
  }

  // 获取App类型树
  queryAppTypeTreeData() {
    let params = {
      name: ''
    };
    AppTypeApi.tree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.appTypeTreeData = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 获取表单列表
  getFormPage(): void {
    let params = {
      applicationId: this.formSearchModel.applicationId,
      title: this.formSearchModel.title,
      processId: this.formSearchModel.processId,
      starter: this.formSearchModel.starter,
      starterGroup: this.formSearchModel.starterGroup,
      // 是否结束
      end: this.formSearchModel.end,
      // 时间范围
      beginTime: this.formSearchModel.beginTime,
      endTime: this.formSearchModel.endTime,
      // 受理人 todo
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    getProcessInstances(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        res.data.list.forEach((item: any) => {
          this.flowTypeList.forEach((val: any) => {
            if (item.applicationId == val.id) {
              item.flowType = val.displayName;
            }
          });
        });
        this.formListData = res.data.list;
        this.total = res.data.total;
      }
    });
  }

  // 点击查询
  handleSearch() {
    this.pageIndex = 1;
    this.getFormPage();
  }

  // 点击重置
  handleSearchReset() {
    this.pageIndex = 1;
    this.formSearchModel = {
      applicationTypeId: null,
      processId: null,
      applicationId: '',
      title: '',
      starter: null,
      timeScope: '',
      name: '',
      status: ''
    };
    if (this.isShowFlow) {
      this.formSearchModel.end = true;
    }
    this.getFormPage();
  }

  // 打开管理详情界面
  openManage(row) {
    this.taskManager.initDialog(row);
  }

  /**
   * 下拉选转换prop
   */
  appTypeTreeNormalizer(node) {
    return {
      id: node.id,
      label: this.$t(node.name),
      children: node.children
    };
  }

  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getFormPage();
  }

  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getFormPage();
  }

  // 左侧树
  /**
   * 获取左侧应用树列表
   */
  getAppTypeTreeData() {
    let params = {
      name: this.filterText
    };
    AppTypeApi.tree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        let data = res.data;
        if (data.length > 0) {
          let tempArr: any[] = [];
          data.forEach((item: any) => {
            item.applications.forEach((val: any) => {
              let { id, displayName } = val;
              tempArr.push({ id, displayName });
            });
          });
          this.flowTypeList = tempArr;
        }
        data.forEach(a => (a.name = a.displayName));

        // 将applications也放到 children中（applications下没有children,children下可能有children）
        this.recursionData(data);
        this.appData = [{ id: '-1', name: this.$t('lang_top_node'), children: data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }
  recursionData(data: any = []): void {
    if (data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length) {
          this.recursionData(data[i].children);
        }
        if (data[i].applications.length && data[i].children) {
          data[i].children.push(...data[i].applications);
        } else if (data[i].applications.length) {
          data[i].children = [];
          data[i].children = data[i].applications;
        }
      }
    }
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    if (data.applicationTypeId) {
      let params = {
        applicationId: data.id,
        title: this.formSearchModel.title,
        starter: this.formSearchModel.starter,
        // 是否结束
        end: this.formSearchModel.end,
        // 时间范围
        beginTime: this.formSearchModel.beginTime,
        endTime: this.formSearchModel.endTime,
        // 受理人 todo
        page: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }
      };
      getProcessInstances(params).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          res.data.list.forEach((item: any) => {
            this.flowTypeList.forEach((val: any) => {
              if (item.applicationId == val.id) {
                item.flowType = val.displayName;
              }
            });
          });
          this.formListData = res.data.list;
          this.total = res.data.total;
        }
      });
    } else {
      this.formListData = [];
      this.total = 0;
    }
  }

  // 改变值
  changeGroupTreeData() {
    this.formSearchModel.starter = null;
    this.getUserList();
  }
  // 获取表单列表
  getUserList() {
    let params = {
      groupId: this.formSearchModel.starterGroup
    };
    getUserListData(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.userListData = res.data;
        }
      })
      .finally(() => {});
  }

  // 加载部门列表
  getDeptList() {
    // 请求参数
    let params = {
      parentId: '-1'
    };
    // 调用接口
    getDeptListData(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.groupTreeData = res.data;
      }
    });
  }
}
