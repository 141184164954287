var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "50%",
        "destroy-on-close": false,
        center: true,
        title: _vm.$t("lang_process_transfer"),
        "append-to-body": "",
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "inputDeep",
              attrs: { model: _vm.form, "label-width": "140px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "当前受理人员：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.receiverName,
                      callback: function ($$v) {
                        _vm.receiverName = $$v
                      },
                      expression: "receiverName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "转交人员：" } },
                [
                  _c("user-transfer", {
                    attrs: {
                      data: _vm.formDelegateTo,
                      placeholder: _vm.$t("lang_please_select_a_person"),
                      multiple: true,
                    },
                    on: {
                      "update:data": function ($event) {
                        _vm.formDelegateTo = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c("br"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }