var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "90%",
            "close-on-click-modal": false,
            "destroy-on-close": true,
            center: true,
            top: "5vh",
            "custom-class": "task-manager-dialog",
            title: _vm.$t("lang_management_matters"),
          },
          on: { close: _vm.handleCloseDialog },
        },
        [
          _c("div", { staticClass: "task-manager-content" }, [
            _c(
              "div",
              { staticClass: "task-info-wrapper" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "inputDeep",
                    attrs: {
                      model: _vm.taskData,
                      inline: "",
                      "label-suffix": ": ",
                      "label-width": "140px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_process_the_title") } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.taskData.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskData, "title", $$v)
                            },
                            expression: "taskData.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_originator") } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.taskData.starterName,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskData, "starterName", $$v)
                            },
                            expression: "taskData.starterName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_launch_time") } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.taskData.startTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskData, "startTime", $$v)
                            },
                            expression: "taskData.startTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_the_end_of_time") } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.taskData.endTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskData, "endTime", $$v)
                            },
                            expression: "taskData.endTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_state") } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.taskData.endStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskData, "endStatus", $$v)
                            },
                            expression: "taskData.endStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_current_task") } },
                      [
                        _c(
                          "el-table",
                          { attrs: { data: _vm.tableData } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_task_name"),
                                width: "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(scope.row.nodeName)) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1409707435
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_current_acceptor"),
                                prop: "performersNames",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_operation"),
                                prop: "address",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              color: "#007aff",
                                              "margin-left": "10px",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.turnPerson(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("lang_transfer")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1939646063
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "task-divider-wrapper" }, [
              _c("div", { staticClass: "task-divider-content" }),
            ]),
            _c(
              "div",
              {
                staticClass: "task-graph-wrapper",
                style: {
                  width:
                    _vm.logs.length > 0 ? "calc(40% - 5px)" : "calc(60% - 5px)",
                },
              },
              [
                _vm.dialogVisible
                  ? _c("flow-graph", {
                      ref: "flowGrapgRef",
                      staticClass: "task-graph-content",
                      attrs: {
                        flowId: _vm.taskData.processId,
                        taskDataId: _vm.taskDataId,
                        readonly: true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "task-divider-wrapper" }, [
              _c("div", { staticClass: "task-divider-content" }),
            ]),
            _vm.logs.length > 0
              ? _c(
                  "div",
                  { staticClass: "task-logs-wrapper" },
                  [
                    _c("FlowLogs", {
                      attrs: { data: _vm.logs, allowUpdate: true },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.jumpButtonShow
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.jumpClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_jump")))]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.changeFormClick },
                },
                [_vm._v(_vm._s(_vm.$t("lang_modify_form")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.deleteClick } },
                [_vm._v(_vm._s(_vm.$t("lang_delete")))]
              ),
              _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
                _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
              ]),
            ],
            1
          ),
          _c("task-delegate", {
            ref: "taskDelegate",
            on: { reloadTasks: _vm.reloadTasks },
          }),
          _c("task-jump", {
            ref: "taskJump",
            on: { reloadTasks: _vm.reloadTasks },
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: _vm.$t("lang_preview"),
                visible: _vm.formVisiable,
                "append-to-body": "",
                top: "5vh",
                width: "70%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.formVisiable = $event
                },
              },
            },
            [
              _vm.formVisiable && _vm.taskData.formId
                ? _c("form-viewer", { ref: "formViewerRef" })
                : _vm._e(),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.formVisiable = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_shut_down")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSaveFormData },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }