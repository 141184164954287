




























import { Component, Ref, Vue } from 'vue-property-decorator';
import UserModal from '@/components/user-transfer/index.vue';
import { JumpToNode, queryJumpNodes } from '@/api/data-manage/TaskApi';

@Component({
  name: 'TaskJump',
  components: { UserModal }
})
export default class TaskDelegate extends Vue {
  // ref
  @Ref() readonly formRef;

  // 弹框是否显示
  dialogVisible: boolean = false;

  // 表单数据
  form: any = {};

  // 节点列表
  nodeSelectList: any = {};

  // 按钮的loading
  buttonLoading: boolean = false;

  // 关闭弹窗
  handleCloseDialog() {
    // 按钮loading
    this.buttonLoading = false;
    this.dialogVisible = false;
  }

  // 打开弹框
  initDialog(row) {
    this.dialogVisible = true;
    this.form = row;

    // 获取弹框下拉列表
    queryJumpNodes({ id: this.form.processId }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.nodeSelectList = res.data;
      }
    });
  }

  // 提交
  submit() {
    // 按钮loading
    this.buttonLoading = true;

    // 请求跳转接口
    JumpToNode({
      processId: this.form.processId,
      runtimeId: this.form.runtimeId,
      id: this.form.id,
      targetNodeId: this.form.nodeSelect
    })
      .then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.dialogVisible = false;

          // 按钮loading
          this.buttonLoading = false;

          this.$message.success(res.message);
          this.$emit('reloadTasks');
        } else {
          // 按钮loading
          this.buttonLoading = false;
          this.$message.error(res.message);
        }
      })
      .catch((res: Record<string, any>) => {
        // 按钮loading
        this.buttonLoading = false;
        this.$message.error(res.message);
      });
  }
}
