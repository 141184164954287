var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent", staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-input", {
                    staticClass: "main-left-tree-filter mgt-10",
                    attrs: {
                      placeholder: _vm.$t("lang_enter_keywords_to_filter"),
                      clearable: "",
                    },
                    on: { clear: _vm.getAppTypeTreeData },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getAppTypeTreeData($event)
                      },
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c("el-tree", {
                    ref: "appTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.appData,
                      "default-expand-all": "",
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-tree-node-title" },
                                  [_vm._v(_vm._s(_vm.$t(data.name)))]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "div",
                { staticClass: "page-search-bar" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.formSearchModel,
                        inline: "",
                        "label-suffix": ": ",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_process_the_title"),
                            "label-width": "121px",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSearch($event)
                              },
                            },
                            model: {
                              value: _vm.formSearchModel.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formSearchModel, "title", $$v)
                              },
                              expression: "formSearchModel.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_sponsor_organization"),
                            "label-width": "215px",
                          },
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.groupTreeData,
                              "append-to-body": "",
                              "z-index": 2999,
                              "default-expand-level": 1,
                              normalizer: _vm.groupTreeNormalizer,
                              placeholder: "",
                            },
                            on: { input: _vm.changeGroupTreeData },
                            model: {
                              value: _vm.formSearchModel.starterGroup,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formSearchModel,
                                  "starterGroup",
                                  $$v
                                )
                              },
                              expression: "formSearchModel.starterGroup",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_originator"),
                            "label-width": "100px",
                          },
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.userListData,
                              normalizer: _vm.groupTreeNormalizer2,
                              placeholder: "",
                              "append-to-body": "",
                              "z-index": 2999,
                              "default-expand-level": 1,
                            },
                            model: {
                              value: _vm.formSearchModel.starter,
                              callback: function ($$v) {
                                _vm.$set(_vm.formSearchModel, "starter", $$v)
                              },
                              expression: "formSearchModel.starter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_initiating_time_range"),
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "end-placeholder": _vm.$t("lang_end_date"),
                              "range-separator": _vm.$t("lang_to"),
                              "start-placeholder": _vm.$t("lang_start_date"),
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.formSearchModel.timeScope,
                              callback: function ($$v) {
                                _vm.$set(_vm.formSearchModel, "timeScope", $$v)
                              },
                              expression: "formSearchModel.timeScope",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.isShowFlow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_state"),
                                "label-width": "80px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t("lang_please_select"),
                                  },
                                  model: {
                                    value: _vm.formSearchModel.end,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formSearchModel, "end", $$v)
                                    },
                                    expression: "formSearchModel.end",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("lang_ended"),
                                      value: true,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("lang_not_finished"),
                                      value: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_process_classification"),
                            prop: "processCode",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c("treeselect", {
                            ref: "processRef",
                            attrs: {
                              normalizer: _vm.formNormalizer,
                              appendToBody: "",
                              zIndex: "9999",
                              "disable-branch-nodes": true,
                              options: _vm.processData,
                            },
                            model: {
                              value: _vm.formSearchModel.processId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formSearchModel, "processId", $$v)
                              },
                              expression: "formSearchModel.processId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "mgb-0" },
                        [
                          _c("el-button", {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.handleSearch },
                          }),
                          _c("el-button", {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-refresh",
                            },
                            on: { click: _vm.handleSearchReset },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#fff",
                    height: "calc(100% - 130px)",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "page-pagetable",
                      attrs: {
                        border: "",
                        data: _vm.formListData,
                        height: "calc(100% - 70px)",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_process_the_title"),
                          align: "left",
                          "class-name": "task-title",
                          prop: "title",
                          width: "600px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.openManage(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.title))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "流程类型",
                          align: "center",
                          prop: "flowType",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_originator"),
                          align: "center",
                          prop: "starterName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_initiating_time_range"),
                          align: "center",
                          prop: "startTime",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatDate(scope.row.startTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_the_end_of_time"),
                          align: "center",
                          prop: "endTime",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatDate(scope.row.endTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_state"),
                          align: "center",
                          prop: "end",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.end
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("lang_not_finished"))
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_ended")))]
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_operation"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#007aff",
                                      "margin-left": "10px",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openManage(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("lang_administration"))
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 20, 30, 40],
                      "page-size": _vm.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.changePageSize,
                      "current-change": _vm.changePage,
                    },
                  }),
                ],
                1
              ),
              _c("task-manage", {
                ref: "taskManager",
                on: { reloadManageList: _vm.handleSearch },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }